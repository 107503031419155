<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="提交成功" left-arrow @click-left="$router.back()" />
    </div>
    <div class="main">
      <div class="box">
        <img style="width:70px;height:70px" src="../assets/images/ok.png" alt />
        <div style="color:#333333;font-size:14px;">您的开票要求已成功提交</div>
        <div style="color:#666;font-size:12px">
         2个工作日内,发票将会发送至您指定的邮箱内
        </div>
        <div style="color:red">{{this.email}}</div>
        <div>请注意查收
        </div>
      </div>

      <div class="foot" @click="go">
        <button class="btn">
          <img style="width:15px;height:15px" src="../assets/images/index.png" alt /> 返回首页
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'TaxiH5Cancel',

  data() {
    return {
      email:''
    }
  },

  mounted() {
   this.email= localStorage.getItem('email')
  },

  methods: {
    //返回首页
    go() {
      this.$router.push({
        path: '/index',
      })
    },
    onClickLeft() {
      Toast('返回')
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;

  .main {
    margin-top: 30%;
    .box {
      text-align: center;
      line-height: 30px;
    }
    .foot {
      .btn {
        width: 90%;
        height: 50px;
        margin: 0 5%;
        background: #fff;
        border-radius: 15px;
        border: 0;
        margin-top: 50%;
        border: solid 1px #999;
        color: #999;
      }
    }
  }
  .imgbox {
    margin-top: 20px;
  }
}
</style>